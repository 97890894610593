import axios from 'axios';

export async function createComment(candidateId, comment) {
    try {
        let response = await axios.post(`https://gobrasa.io/api/bconnect/comments/${candidateId}`,
            {
                comment,
            }
        );

        return response.data;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

export async function editComment(commentId, comment) {
    try {
        let response = await axios.put(`https://gobrasa.io/api/bconnect/comments/${commentId}`,
            {
                comment,
            }
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

export async function deleteCommment(commentId) {
    try {
        let response = await axios.delete(`https://gobrasa.io/api/bconnect/comments/${commentId}`, {},
        );

        return response.status;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return false;
    }
}

export async function getComments(candidateId) {
    try {
        let response = await axios.get(`https://gobrasa.io/api/bconnect/comments/${candidateId}`, {}
        );

        return response.data;
    }
    catch (error) {
        console.log(error); // TODO improve error handling
        return [];
    }
}
